import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { getCustomerPortalURL } from "../api/mailerfind/stripe";
import { auth } from "../lib/firebase/firebase";
import { UserContext } from "../contexts/UserContext";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useCustomerPortal = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext) as any;
  const { t } = useTranslation("MyAccount.Billing");
  const location = useLocation();

  const buildStripeReturnUrl = () => {
    return `http://${window.location.hostname}:${window.location.port}${location.pathname}`;
  };

  const checkIfIsImpersonating = async () => {
    const user = await auth.currentUser?.getIdTokenResult();
    return user?.claims.isImpersonated;
  };

  const redirectToCustomerPortal = async () => {
    if (!user) return;

    const isImpersonated = await checkIfIsImpersonating();
    if (isImpersonated) {
      toast.error(t("impersonating_error"));
      return;
    }

    setLoading(true);
    const stripeReturnUrl = buildStripeReturnUrl();
    try {
      const jwt = await auth.currentUser!.getIdToken();
      const response = await getCustomerPortalURL(stripeReturnUrl, jwt);
      window.location.href = response.link;
    } catch (err: any) {
      console.error(err);
      toast.error(err.message);
    }
  };

  return {
    loading,
    redirectToCustomerPortal,
  };
};

export default useCustomerPortal;
