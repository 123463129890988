import {
  collection,
  getDocs,
  orderBy,
  query,
  startAfter,
  limit as firebaseLimit,
  where,
  DocumentSnapshot,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "./firebase";

const PAGE_SIZE = 100;

// Función auxiliar para determinar si el análisis es versión 2 o superior
const isAnalysisVersion2 = async (userId: string, analysisId: string) => {
  const analysisDocRef = doc(db, "users", userId, "analysis", analysisId);
  const analysisDocSnapshot = await getDoc(analysisDocRef);
  if (analysisDocSnapshot.exists()) {
    const analysisData = analysisDocSnapshot.data();
    return analysisData.version >= 2;
  } else {
    console.log("No such document!");
    return false; // Asumimos que no es versión 2 si el documento no existe
  }
};

export const getNotSentProspects = async (analysisId: any, emailId: any, userId: any, limit: number, lastDoc?: any) => {
  // Verificar si el análisis es versión 2
  const version2 = await isAnalysisVersion2(userId, analysisId);

  let prospectsCollectionRef;
  let paginatedQuery;

  if (version2) {
    prospectsCollectionRef = collection(db, "users", userId, "prospects");
    // Modificar la consulta para incluir el filtro por ID de análisis en la versión 2
    paginatedQuery = query(prospectsCollectionRef, where("analysis", "array-contains", analysisId), where("email", "!=", null));
  } else {
    prospectsCollectionRef = collection(db, "users", userId, "analysis", analysisId, "prospects");
    paginatedQuery = query(prospectsCollectionRef, where("email", "!=", null));
  }

  if (limit) {
    paginatedQuery = query(paginatedQuery, firebaseLimit(limit));
  }
  if (lastDoc) {
    paginatedQuery = query(paginatedQuery, startAfter(lastDoc));
  }

  // Execute the query
  const querySnapshot = await getDocs(paginatedQuery);

  // Return the prospects
  const prospects = [] as any;
  querySnapshot.forEach((doc: any) => {
    const data = doc.data();
    // Only include prospects who haven't been sent the email
    const sentEmailsIds = data.sentEmailsIds || [];
    const skippedEmailsIds = data.skippedEmailsIds || [];
    if (!sentEmailsIds.includes(emailId) && !skippedEmailsIds.includes(emailId)) {
      prospects.push({ id: doc.id, ...data });
    }
  });

  console.log("not sent prospects", prospects);

  return {
    prospects,
    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1], // Return the last document for cursor-based pagination
  };
};

export const getSentProspects = async (analysisId: any, emailId: any, userId: any, limit: number, lastDoc?: any) => {
  // Verificar si el análisis es versión 2
  const version2 = await isAnalysisVersion2(userId, analysisId);

  let prospectsCollectionRef;
  let paginatedQuery;

  if (version2) {
    prospectsCollectionRef = collection(db, "users", userId, "prospects");
    // Modificar la consulta para incluir el filtro por ID de análisis en la versión 2
    paginatedQuery = query(prospectsCollectionRef, where("analysis", "array-contains", analysisId), where("email", "!=", null));
  } else {
    prospectsCollectionRef = collection(db, "users", userId, "analysis", analysisId, "prospects");
    paginatedQuery = query(prospectsCollectionRef, where("email", "!=", null));
  }

  if (limit) {
    paginatedQuery = query(paginatedQuery, firebaseLimit(limit));
  }
  if (lastDoc) {
    paginatedQuery = query(paginatedQuery, startAfter(lastDoc));
  }

  // Execute the query
  const querySnapshot = await getDocs(paginatedQuery);

  // Return the prospects
  const prospects = [] as any;
  querySnapshot.forEach((doc: any) => {
    const data = doc.data();
    // Only include prospects who already have been sent the email
    const sentEmailsIds = data.sentEmailsIds || [];
    if (sentEmailsIds.includes(emailId)) {
      // Double check to ensure the email exists and is not an empty string
      prospects.push({ id: doc.id, ...data });
    }
  });

  return {
    prospects,
    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1], // Return the last document for cursor-based pagination
  };
};

export const getReadProspects = async (analysisId: any, emailId: any, userId: any, limit: number, lastDoc?: any) => {
  // Verificar si el análisis es versión 2
  const version2 = await isAnalysisVersion2(userId, analysisId);

  let prospectsCollectionRef;
  let paginatedQuery;

  if (version2) {
    prospectsCollectionRef = collection(db, "users", userId, "prospects");
    // Modificar la consulta para incluir el filtro por ID de análisis en la versión 2
    paginatedQuery = query(prospectsCollectionRef, where("sentEmailsIds", "array-contains", emailId));
  } else {
    prospectsCollectionRef = collection(db, "users", userId, "analysis", analysisId, "prospects");
    paginatedQuery = query(prospectsCollectionRef, where("sentEmailsIds", "array-contains", emailId));
  }

  if (limit) {
    paginatedQuery = query(paginatedQuery, firebaseLimit(limit));
  }
  if (lastDoc) {
    paginatedQuery = query(paginatedQuery, startAfter(lastDoc));
  }

  // Execute the query
  const querySnapshot = await getDocs(paginatedQuery);

  // Return the prospects
  const prospects = [] as any;
  querySnapshot.forEach((doc: any) => {
    const prospect = doc.data();
    const hasOpenedSpecificEmail = prospect.sentEmails && prospect.sentEmails.some((email: any) => email.id === emailId && email.opened);
    if (hasOpenedSpecificEmail) {
      prospects.push({ id: doc.id, ...prospect });
    }
  });

  return {
    prospects,
    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1], // Return the last document for cursor-based pagination
  };
};

export const getClickedProspects = async (analysisId: any, emailId: any, userId: any, limit: number, lastDoc?: any) => {
  const version2 = await isAnalysisVersion2(userId, analysisId);

  let prospectsCollectionRef;
  let paginatedQuery;

  if (version2) {
    prospectsCollectionRef = collection(db, "users", userId, "prospects");
    // Modificar la consulta para incluir el filtro por ID de análisis en la versión 2
    paginatedQuery = query(prospectsCollectionRef, where("sentEmailsIds", "array-contains", emailId));
  } else {
    prospectsCollectionRef = collection(db, "users", userId, "analysis", analysisId, "prospects");
    paginatedQuery = query(prospectsCollectionRef, where("sentEmailsIds", "array-contains", emailId));
  }

  if (limit) {
    paginatedQuery = query(paginatedQuery, firebaseLimit(limit));
  }
  if (lastDoc) {
    paginatedQuery = query(paginatedQuery, startAfter(lastDoc));
  }

  // Execute the query
  const querySnapshot = await getDocs(paginatedQuery);

  // Return the prospects
  const prospects = [] as any;
  querySnapshot.forEach((doc: any) => {
    const data = doc.data();
    if (!data.sentEmails) return;
    const index = data.sentEmails?.findIndex((email: any) => email.id === emailId);
    for (let i = 0; i < data.sentEmails[index]?.clicks?.length; i++) {
      data.sentEmails[index].clicks[i].prospect = { id: doc.id, ...data };
    }
    if (data.sentEmails[index]?.clicked === true) {
      prospects.push(...data.sentEmails[index]?.clicks);
    }
  });

  return {
    prospects,
    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1], // Return the last document for cursor-based pagination
  };
};

export const getEmailClicks = async (analysisId: any, emailId: any, userId: any, limit: number, lastDoc?: any) => {
  const version2 = await isAnalysisVersion2(userId, analysisId);

  let prospectsCollectionRef;
  let paginatedQuery;

  if (version2) {
    prospectsCollectionRef = collection(db, "users", userId, "prospects");
    // Modificar la consulta para incluir el filtro por ID de análisis en la versión 2
    paginatedQuery = query(prospectsCollectionRef, where("analysis", "array-contains", analysisId), where("email", "!=", null));
  } else {
    prospectsCollectionRef = collection(db, "users", userId, "analysis", analysisId, "prospects");
    paginatedQuery = query(prospectsCollectionRef, where("email", "!=", null));
  }

  if (limit) {
    paginatedQuery = query(paginatedQuery, firebaseLimit(limit));
  }
  if (lastDoc) {
    paginatedQuery = query(paginatedQuery, startAfter(lastDoc));
  }

  // Execute the query
  const querySnapshot = await getDocs(paginatedQuery);

  // Return the prospects
  let clicks = [] as any;
  querySnapshot.forEach((doc: any) => {
    const data = doc.data();
    if (!data.sentEmails) return;
    const index = data.sentEmails?.findIndex((email: any) => email.id === emailId);
    clicks = data.sentEmails[index].clicks || [];
  });

  return {
    clicks,
    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1], // Return the last document for cursor-based pagination
  };
};

export const getEmailClicksGrouped = async (analysisId: any, emailId: any, userId: any, limit: number, lastDoc?: any) => {
  const version2 = await isAnalysisVersion2(userId, analysisId);

  let prospectsCollectionRef;
  let paginatedQuery;

  if (version2) {
    prospectsCollectionRef = collection(db, "users", userId, "prospects");
    // Modificar la consulta para incluir el filtro por ID de análisis en la versión 2
    paginatedQuery = query(prospectsCollectionRef, where("analysis", "array-contains", analysisId), where("email", "!=", null));
  } else {
    prospectsCollectionRef = collection(db, "users", userId, "analysis", analysisId, "prospects");
    paginatedQuery = query(prospectsCollectionRef, where("email", "!=", null));
  }

  if (limit) {
    paginatedQuery = query(paginatedQuery, firebaseLimit(limit)); // Asumo que tienes una función firebaseLimit que establece el límite
  }
  if (lastDoc) {
    paginatedQuery = query(paginatedQuery, startAfter(lastDoc));
  }

  // Ejecutar la consulta
  const querySnapshot = await getDocs(paginatedQuery);

  // Objeto para almacenar el conteo de clicks por URL
  const urlClickCounts: { [url: string]: number } = {};

  // Iterar a través de los documentos devueltos
  querySnapshot.forEach((doc: any) => {
    const data = doc.data();
    if (!data.sentEmails) return;
    const index = data.sentEmails?.findIndex((email: any) => email.id === emailId);
    const clicks = data.sentEmails[index]?.clicks || [];

    // Contar los clicks por URL
    clicks.forEach((click: any) => {
      if (click.url) {
        urlClickCounts[click.url] = (urlClickCounts[click.url] || 0) + 1;
      }
    });
  });

  // Convertir el objeto a un array de strings para el resultado
  const groupedClicks = Object.entries(urlClickCounts).map(([url, count]) => `URL ${url} - ${count} clicks`);

  return {
    clicks: groupedClicks,
    lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
  };
};

export const getProspectByUsername = async (userId: string, username: string): Promise<any> => {
  let prospectsCollectionRef = collection(db, "users", userId, "prospects");
  // Modificar la consulta para incluir el filtro por ID de análisis en la versión 2
  let q = query(prospectsCollectionRef, where("username", "==", username));

  const querySnapshot = await getDocs(q);

  const prospects = [] as any;
  querySnapshot.forEach((doc) => {
    prospects.push({ id: doc.id, ...doc.data() });
  });

  if (prospects.length === 0) {
    throw new Error("Prospect not found");
  }

  return prospects[0];
};

export const getProspectByUsernameInAnalysis = async (userId: string, username: string, analysisId: string): Promise<any> => {
  let prospectsCollectionRef = collection(db, "users", userId, "prospects");

  // Consulta con dos filtros: uno por nombre de usuario y otro por presencia de analysisId en el campo analysis
  let q = query(prospectsCollectionRef, where("username", "==", username), where("analysis", "array-contains", analysisId));

  const querySnapshot = await getDocs(q);

  const prospects = [] as any;
  querySnapshot.forEach((doc) => {
    prospects.push({ id: doc.id, ...doc.data() });
  });

  if (prospects.length === 0) {
    throw new Error("Prospect not found");
  }

  return prospects[0];
};

export const getProspectsWithPagination = async (
  userId: string,
  analysisId: string,
  lastVisible?: DocumentSnapshot
): Promise<{ prospects: any[]; lastVisible: DocumentSnapshot | null }> => {
  // Verificar si el análisis es versión 2
  const version2 = await isAnalysisVersion2(userId, analysisId);

  let prospectsCollectionRef;
  let q;

  if (version2) {
    prospectsCollectionRef = collection(db, "users", userId, "prospects");
    // Crear una consulta que ordene los prospectos (por ejemplo, por fecha de creación)
    // y que limite la cantidad de resultados a PAGE_SIZE.
    q = query(prospectsCollectionRef, where("analysis", "array-contains", analysisId), orderBy("username"), firebaseLimit(PAGE_SIZE));
  } else {
    // Crear una consulta que ordene los prospectos (por ejemplo, por fecha de creación)
    // y que limite la cantidad de resultados a PAGE_SIZE.
    prospectsCollectionRef = collection(db, "users", userId, "analysis", analysisId, "prospects");
    q = query(prospectsCollectionRef, orderBy("username"), firebaseLimit(PAGE_SIZE));
  }

  // Si se proporciona un último documento visible, ajusta la consulta para empezar después de ese documento.
  if (lastVisible) {
    q = query(prospectsCollectionRef, orderBy("username"), startAfter(lastVisible), firebaseLimit(PAGE_SIZE));
  }

  const querySnapshot = await getDocs(q);

  const prospects = [] as any;
  querySnapshot.forEach((doc) => {
    prospects.push({ id: doc.id, ...doc.data() });
  });

  // Retorna los prospectos y el último documento visible para usarlo en la próxima llamada.
  return {
    prospects,
    lastVisible: querySnapshot.docs[querySnapshot.docs.length - 1] || null,
  };
};

export const getAllProspectsInAnalysisWithFilter = async (userId: string, analysisId: string, filter: string, limit = 100) => {
  const isVersion2 = await isAnalysisVersion2(userId, analysisId);
  const safeLimit = Math.min(limit, PAGE_SIZE);

  let prospectsCollectionRef;

  if (isVersion2) {
    prospectsCollectionRef = collection(db, "users", userId, "prospects");
  } else {
    prospectsCollectionRef = collection(db, "users", userId, "analysis", analysisId, "prospects");
  }

  let q;
  switch (filter) {
    case "email":
      q = isVersion2
        ? query(prospectsCollectionRef, where("analysis", "array-contains", analysisId), where("email", "!=", null))
        : query(prospectsCollectionRef, where("email", "!=", null));
      break;
    case "phone_number":
      q = isVersion2
        ? query(prospectsCollectionRef, where("analysis", "array-contains", analysisId), where("phone_number", "!=", null))
        : query(prospectsCollectionRef, where("phone_number", "!=", null));
      break;
    case "website":
      q = isVersion2
        ? query(prospectsCollectionRef, where("analysis", "array-contains", analysisId), where("website", "!=", null))
        : query(prospectsCollectionRef, where("website", "!=", null));
      break;
    case "name_and_email": // Este caso parece ser idéntico al caso "email", quizás quieras aplicar una lógica diferente aquí
      q = isVersion2
        ? query(prospectsCollectionRef, where("analysis", "array-contains", analysisId), where("email", "!=", null))
        : query(prospectsCollectionRef, where("email", "!=", null));
      break;
    default:
      return [];
  }

  // add limit
  q = query(q, firebaseLimit(safeLimit));

  const querySnapshot = await getDocs(q);
  const prospects = [] as any[];
  querySnapshot.forEach((doc) => {
    prospects.push({ id: doc.id, ...doc.data() });
  });

  return prospects;
};

export const searchProspects = async (userId: string, analysisId: string, search: string) => {
  const isVersion2 = await isAnalysisVersion2(userId, analysisId);

  let prospectsCollectionRef;
  if (isVersion2) {
    prospectsCollectionRef = collection(db, "users", userId, "prospects");
  } else {
    prospectsCollectionRef = collection(db, "users", userId, "analysis", analysisId, "prospects");
  }

  // Inicia el rango con el término de búsqueda
  const startAt = search;
  // Finaliza el rango con el término de búsqueda seguido del último caracter unicode posible
  const endAt = search + "\uf8ff";

  let q;
  if (isVersion2) {
    q = query(
      prospectsCollectionRef,
      where("analysis", "array-contains", analysisId),
      where("username", ">=", startAt),
      where("username", "<=", endAt)
    );
  } else {
    q = query(prospectsCollectionRef, where("username", ">=", startAt), where("username", "<=", endAt));
  }

  // limit to 100 results
  q = query(q, firebaseLimit(100));

  const querySnapshot = await getDocs(q);
  const prospects = [] as any[];
  querySnapshot.forEach((doc) => {
    prospects.push({ id: doc.id, ...doc.data() });
  });

  return prospects;
};
