import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { exportAnalysis } from "../../api/mailerfind/exports";
import toast from "react-hot-toast";
import { auth } from "../../lib/firebase/firebase";

const formatLabels: { [key: string]: string } = {
  csv: "CSV",
  excel: "Excel",
};

type ExportPopUpProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  analysisId: any;
  format: string;
};

function ExportPopUp({ show, setShow, analysisId, format: defaultFormat }: ExportPopUpProps) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("Component.ExportPopUp");

  const handleClose = () => setShow(false);

  const [filters, setFilters] = useState({
    email: true,
    website: false,
    phone_number: false,
  });

  useEffect(() => {
    setFormat(defaultFormat);
  }, [defaultFormat]);

  const [format, setFormat] = useState(defaultFormat);

  const [subfilters, setSubfilters] = useState({
    email: "all",
  });

  const handleFilterChange = (filter: keyof typeof filters) => {
    setFilters((prev) => ({
      ...prev,
      [filter]: !prev[filter],
    }));
  };

  const handleFormatChange = (newFormat: string) => {
    setFormat(newFormat);
  };

  const handleEmailSubfilterChange = (subfilter: string) => {
    setSubfilters((prev) => ({
      ...prev,
      email: subfilter,
    }));
  };

  const handleSubmit = async () => {
    const startExport = async (filters: any, format: string) => {
      try {
        setLoading(true);
        const jwt = await auth.currentUser!.getIdToken();
        const activeFilters = Object.keys(filters).filter((key) => filters[key]);
        const response = await exportAnalysis(analysisId, activeFilters, subfilters, format, jwt);
        if (response.queued) {
          toast.success(t("toast.exportQueued"));
          setShow(false);
          return;
        }
        window.open(response.url, "_blank");
        toast.success(t("toast.exportDownloaded"));
        if (!response.queued) setShow(false);
        return response.queued;
      } catch (err: any) {
        toast.error(err.message || t("toast.exportError"));
      } finally {
        setLoading(false);
      }
    };

    await startExport(filters, format);
  };

  return (
    <Modal show={show} onHide={handleClose} size="sm" backdrop={loading ? "static" : true} keyboard={!loading}>
      <Modal.Header>
        <Modal.Title>{t("modal.title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="text-dark">{t("labels.format")}</span>
        <div className="btn-group w-100 mt-2 mb-4">
          <button
            className="btn btn-white dropdown-toggle"
            type="button"
            id="dropdownMenuButtonWhite"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              className="avatar avatar-xss avatar-4by3 mr-2"
              src={format === "csv" ? "/assets/svg/components/placeholder-csv-format.svg" : "/assets/svg/brands/excel.svg"}
              alt={t(`formats.${format}`)}
            />
            <span className="align-middle">{t(`formats.${format}`)}</span>
          </button>
          <div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
            <div className="dropdown-item px-3" style={{ cursor: "pointer" }} onClick={() => handleFormatChange("csv")}>
              <img
                className="avatar avatar-xss avatar-4by3 mr-2"
                src="/assets/svg/components/placeholder-csv-format.svg"
                alt={t("formats.csv")}
              />
              <span className="align-middle">{t("formats.csv")}</span>
            </div>
            <div className="dropdown-item px-3" style={{ cursor: "pointer" }} onClick={() => handleFormatChange("excel")}>
              <img className="avatar avatar-xss avatar-4by3 mr-2" src="/assets/svg/brands/excel.svg" alt={t("formats.excel")} />
              <span className="align-middle">{t("formats.excel")}</span>
            </div>
          </div>
        </div>

        <span className="text-dark">{t("labels.filters")}</span>
        <div className="mt-2 mb-4">
          {Object.keys(filters).map((key) => (
            <div key={key} className="form-check" style={{ paddingLeft: "1.5rem" }}>
              <input
                type="checkbox"
                id={`formInlineCheck-${key}`}
                className="form-check-input"
                checked={filters[key as keyof typeof filters]} // Conversión explícita
                onChange={() => handleFilterChange(key as keyof typeof filters)}
              />
              <label className="form-check-label" htmlFor={`formInlineCheck-${key}`}>
                {t(`filters.${key}`)}
              </label>
            </div>
          ))}
        </div>

        {filters.email && (
          <>
            <span className="text-dark">{t("labels.emailSubfilters")}</span>
            <div className="btn-group w-100 mt-2">
              <button
                className="btn btn-white dropdown-toggle"
                type="button"
                id="dropdownMenuButtonWhite"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="mr-1">{t(`emailSubfilters.${subfilters.email}`)}</span>
              </button>
              <div className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton">
                {["all", "b2b", "b2c"].map((subfilter) => (
                  <div
                    key={subfilter}
                    className="dropdown-item px-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleEmailSubfilterChange(subfilter)}
                  >
                    <span className="align-middle">
                      {t(`emailSubfilters.${subfilter}`)}{" "}
                      {subfilter === "all" && <span className="badge bg-soft-success text-success">{t("badges.recommended")}</span>}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-white" onClick={handleClose} disabled={loading}>
          {t("buttons.cancel")}
        </button>
        <button className="btn btn-primary" onClick={handleSubmit} disabled={loading}>
          {loading ? (
            <div className="px-4">
              <span className="spinner-border spinner-border-sm align-middle" role="status" aria-hidden="true"></span>
            </div>
          ) : (
            t("buttons.export")
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ExportPopUp;
