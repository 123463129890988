import React, { useState } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { impersonate } from "../api/mailerfind/users";
import { auth } from "../lib/firebase/firebase";
import { signInWithCustomToken } from "firebase/auth";
import { useTranslation } from "react-i18next";

function ImpersonateModal({ show, setShow }: any) {
  const { t } = useTranslation("Component.ImpersonateModal");
  const [userEmail, setUserEmail] = useState("");
  const handleClose = () => setShow(false);
  const [loading, setLoading] = useState(false);

  const handleImpersonate = async (e: any) => {
    try {
      e.preventDefault();
      setLoading(true);
      const jwt = await auth.currentUser!.getIdToken();
      const response = await impersonate(userEmail.trim(), jwt);
      console.log(response);
      await signInWithCustomToken(auth, response.token);
      handleClose();
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} size="sm">
        <Form onSubmit={handleImpersonate}>
          <Modal.Header>
            <Modal.Title>{t("impersonateTitle")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>{t("userEmail")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={t("emailPlaceholder")}
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="white" type="button" onClick={handleClose}>
              {t("cancel")}
            </Button>
            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : t("confirm")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

export default ImpersonateModal;
