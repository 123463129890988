import { collection, doc, getDoc, limit as firebaseLimit, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "./firebase";

/**
 * Tamaño de página utilizado para limitar la cantidad de exportaciones en cada consulta.
 */
const PAGE_SIZE = 100;

/**
 * Obtiene todos los documentos de exportación de un usuario específico.
 * @param {string} userId - ID del usuario cuyos exportaciones se quieren obtener.
 * @returns {Promise<any[]>} - Una lista de exportaciones del usuario.
 */
export const getExports = async (userId: string, enriched: boolean = false): Promise<any[]> => {
  const exportsCollectionRef = collection(db, "users", userId, "exports");

  // Agrega el ordenamiento por createdAt a la consulta
  const q = query(exportsCollectionRef, orderBy("createdAt", "desc"), firebaseLimit(PAGE_SIZE));

  const querySnapshot = await getDocs(q);
  const exports = [] as any[];
  querySnapshot.forEach(async (doc) => {
    exports.push({ id: doc.id, ...doc.data() });
  });

  return exports;
};

/**
 * Obtiene un único documento de exportación de un usuario por su ID de exportación.
 * @param {string} userId - ID del usuario propietario del export.
 * @param {string} exportId - ID específico del export que se quiere obtener.
 * @returns {Promise<any | null>} - El export especificado o null si no existe.
 */
export const getExportById = async (userId: string, exportId: string): Promise<any | null> => {
  const exportDocRef = doc(db, "users", userId, "exports", exportId);
  const exportDoc = await getDoc(exportDocRef);

  return exportDoc.exists() ? { id: exportDoc.id, ...exportDoc.data() } : null;
};
