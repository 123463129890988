import { useTranslation } from "react-i18next";
import Header from "../../components/Header";
import React from "react";
import Integration from "./Integration";
import { integrations } from "../../utils/constants";

function Integrations() {
  const { t } = useTranslation("Integrations");

  return (
    <div>
      <Header title={t("title")} subtitle={t("subtitle")} breadCrumbItems={[{ href: "/integrations", title: t("title") }]}></Header>
      <div className="row">
        {integrations.map((integration) => {
          return (
            <React.Fragment key={integration.id}>
              {integration.published && (
                <div className="col-lg-4 mb-4">
                  <Integration integration={integration} />
                </div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default Integrations;
