import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { UserContext } from "../../contexts/UserContext";
import Header from "../../components/Header";
import { getExports } from "../../lib/firebase/exports";
import { Export } from "../../types/ExportTypes";
import Moment from "react-moment";
import { Clipboard, Download, QuestionCircle } from "react-bootstrap-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function Exports() {
  const { user } = useContext(UserContext) as any;
  const [exports, setExports] = useState<Export[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const exports = await getExports(user.firebaseUser.uid);
      setExports(exports);
    } catch (err: unknown) {
      if (err instanceof Error) {
        toast.error("Failed to fetch exports: " + err.message);
      } else {
        toast.error("An unknown error occurred");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCopyDownloadUrl = (url: string | null) => {
    if (!url) return;
    navigator.clipboard.writeText(url);
    toast.success("URL copied to clipboard");
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <Header
        title="Exports"
        subtitle="Exported data"
        breadCrumbItems={[{ title: "Analysis", href: "/analysis-manager" }, { title: "Exports" }]}
      />
      <div className="card">
        <div className="card-header">
          <h4 className="card-header-title">Exports</h4>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive datatable-custom" style={{ maxHeight: 400 }}>
            <table className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table dataTable no-footer">
              <thead className="thead-light font-weight-light" style={{ position: "sticky", top: 0, zIndex: 1 }}>
                <tr>
                  <th></th>
                  <th>ID</th>
                  <th>Status</th>
                  <th>Creado</th>
                  <th>Completado</th>
                  <th>Checksum</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                {exports.map((item: Export, index: number) => {
                  const dynamicTooltip = (
                    <Tooltip id={`tooltip-${index}`}>
                      <div>{item.error}</div>
                    </Tooltip>
                  );

                  return (
                    <tr key={index}>
                      <td className="text-center">
                        {item.format === "smartlead" && (
                          <img className="avatar avatar-xs" src="/assets/img/smartlead.webp" alt="Smartlead" />
                        )}
                        {item.format === "csv" && (
                          <img className="avatar avatar-xs" src="/assets/svg/components/placeholder-csv-format.svg" alt="CSV" />
                        )}
                        {item.format === "excel" && <img className="avatar avatar-xs" src="/assets/svg/brands/excel.svg" alt="Excel" />}
                      </td>
                      <td>{item.id}</td>
                      <td>
                        {item.status.toUpperCase()}{" "}
                        {item.status === "failed" && item.error && (
                          <OverlayTrigger placement="left" overlay={dynamicTooltip}>
                            <QuestionCircle size="12" />
                          </OverlayTrigger>
                        )}
                      </td>
                      <td>
                        <Moment format="DD/MM/YYYY HH:mm">{item.createdAt.toDate()}</Moment>
                      </td>
                      <td>{item.completedAt && <Moment format="DD/MM/YYYY HH:mm">{item.completedAt.toDate()}</Moment>}</td>
                      <td>{item.prospectsCount || "-"}</td>
                      <td>
                        <a
                          href={`/exports/${item.id}/download`}
                          target="_blank"
                          rel="noreferrer"
                          className={`btn btn-white btn-sm mr-2 ${item.status !== "completed" && "disabled"}`}
                        >
                          <Download />
                          <span className="align-middle ml-1">Descargar</span>
                        </a>
                        <button className="btn btn-white btn-sm" onClick={() => handleCopyDownloadUrl(item.downloadUrl)}>
                          <Clipboard />
                          <span className="align-middle ml-1">Copiar URL</span>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Exports;
