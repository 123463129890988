import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState, useRef, useContext } from "react";
import { auth, db } from "../../lib/firebase/firebase";
import Header from "../../components/Header";
import { useParams } from "react-router-dom";
import { STATUS } from "../../utils/constants";
import {
  addEmailSentToProspect as addEmailSentToLocalProspect,
  getNotSentProspects as getNotSentLocalProspects,
} from "../../lib/daos/prospectsDAO";
import { analysisIsCloud, isEmail } from "../../utils/helpers";
import { getNotSentProspects as getNotSentCloudProspects } from "../../lib/firebase/prospects";
import { send } from "../../api/mailerfind/email";
import { updateEmail } from "../../lib/firebase/emails";
import { Envelope, PauseFill, Play, PlayFill } from "react-bootstrap-icons";
import { getAnalysis } from "../../lib/daos/analysisDAO";
import ProgressBar from "../../components/ProgressBar";
import Loading from "../../components/Loading";
import Notification from "../../components/Notification";
import PremiumModal from "../../components/PremiumModal";
import { UserContext } from "../../contexts/UserContext";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

// Firebase DB Fetcher functions
async function fetchEmailFromFirebase(uid: any, id: any) {
  const userRef = doc(db, "users", uid);
  const emailRef = doc(userRef, "emails", id);
  const docSnap = await getDoc(emailRef);
  const email = docSnap.data() as any;
  email.id = docSnap.id;
  return email;
}

async function fetchSenderFromFirebase(uid: any, id: any) {
  const userRef = doc(db, "users", uid);
  const senderRef = doc(userRef, "senders", id);
  const docSnap = await getDoc(senderRef);
  return docSnap.exists() ? docSnap.data() : {};
}

async function fetchAnalysisFromFirebase(uid: any, id: any) {
  const userRef = doc(db, "users", uid);
  const analysisRef = doc(userRef, "analysis", id);
  const docSnap = await getDoc(analysisRef);
  return docSnap.exists() ? docSnap.data() : {};
}

function SendEmail() {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("SendEmail.index");

  const BATCH_SIZE = 500;

  const [authUser] = useState(auth.currentUser) as any;
  const { user } = useContext(UserContext) as any;

  const [show, setShow] = useState(false);
  const [email, setEmail] = React.useState<any>({});
  const [sender, setSender] = React.useState<any>({});
  const [analysis, setAnalysis] = React.useState<any>({});
  const [errorMessage, setErrorMessage] = React.useState<any>(null);

  // Refs
  const componentMounted = useRef(true);
  const hasToPause = useRef(false);

  useEffect(() => {
    const componentMounted = { current: true };

    const fetchAndSetData = async () => {
      try {
        const email = await fetchEmailFromFirebase(authUser?.uid, id);
        setEmail(email);

        const runningStatuses = [STATUS.RUNNING, STATUS.PAUSING, STATUS.STARTING];
        setEmail((prevState: any) => ({
          ...prevState,
          status: runningStatuses.includes(email.status) ? STATUS.PAUSED : email.status,
        }));

        const senderId = email.sender || email.currentSender || email.senders[0];
        const sender = await fetchSenderFromFirebase(authUser.uid, senderId);
        setSender(sender);

        const analysisFetcher = analysisIsCloud(email.analysis) ? fetchAnalysisFromFirebase : getAnalysis;
        const analysis = await analysisFetcher(authUser.uid, email.analysis);
        setAnalysis(analysis);

        let prospects = [] as any;
        if (analysisIsCloud(email.analysis)) {
          const response = await getNotSentCloudProspects(email.analysis, email.id, authUser.uid, BATCH_SIZE, null);
          prospects = response.prospects;
        } else {
          prospects = await getNotSentLocalProspects(Number(email.analysis), email.id, 0, BATCH_SIZE);
        }

        if (prospects.length > 0 && email.status === "COMPLETED") {
          setEmail((prevState: any) => ({ ...prevState, status: STATUS.PAUSED }));
        }
      } catch (error) {
        toast.error(t("errorFetchingData"));
        console.error("Error fetching data: ", error);
      }
    };

    fetchAndSetData();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (componentMounted.current) {
        componentMounted.current = false;
      }
    };
  }, []);

  useEffect(() => {
    if (!email.id) return;
    updateEmail(authUser.uid, email.id, { status: email.status, sent: email.sent });
  }, [email]);

  const handleStart = async () => {
    if (user.firebaseUser.plan === "free") {
      setShow(true);
      return;
    }

    setEmail((prevState: any) => ({ ...prevState, status: STATUS.RUNNING }));
    setErrorMessage("");
    let prospects = [] as any;
    let lastDoc = null as any;
    let page = 0;
    let sentEmails = email.sent;

    do {
      if (analysisIsCloud(email.analysis)) {
        const response = await getNotSentCloudProspects(email.analysis, email.id, authUser.uid, BATCH_SIZE, lastDoc);
        prospects = response.prospects;
        lastDoc = response.lastDoc;
      } else {
        prospects = await getNotSentLocalProspects(Number(email.analysis), email.id, page, BATCH_SIZE);
        page++;
      }

      for (const prospect of prospects) {
        if (Array.isArray(prospect.email) && prospect.email.length > 0) {
          prospect.email = prospect.email[0];
        }

        if (!prospect.email) continue;
        prospect.email = prospect.email.trim();
        if (!isEmail(prospect.email)) continue;
        if (typeof prospect.email !== "string") continue;

        try {
          const jwt = await auth.currentUser!.getIdToken();
          const newProspect = {
            email: prospect.email,
            first_name: prospect.first_name,
            phone: prospect.phone,
            website: prospect.website,
            username: prospect.username,
          };
          await send(email.id, newProspect, false, jwt);

          const senderId = email.sender || email.currentSender || email.senders[0];
          const sender = await fetchSenderFromFirebase(authUser.uid, senderId);
          setSender(sender);

          console.log(t("emailSentTo"), prospect.email);
        } catch (err: any) {
          setErrorMessage(err.message);
          setEmail((prevState: any) => ({ ...prevState, status: STATUS.ERROR }));
          return;
        }

        if (analysisIsCloud(email.analysis)) {
        } else {
          await addEmailSentToLocalProspect(prospect.id, email.id);
        }

        sentEmails++;
        setEmail((prevState: any) => ({ ...prevState, sent: prevState.sent + 1 }));

        if (!componentMounted.current) return;
        if (hasToPause.current) {
          setEmail((prevState: any) => ({
            ...prevState,
            status: STATUS.PAUSED,
          }));
          hasToPause.current = false;
          break; // Rompe el bucle limpio
        }

        const delayBetweenEmails = sender?.delayBetweenEmails || 5;
        await new Promise((resolve) => setTimeout(resolve, delayBetweenEmails * 1000));
      }
    } while (sentEmails < analysis?.emailsFoundCount);

    setEmail((prevState: any) => ({ ...prevState, status: STATUS.COMPLETED }));
  };

  const handlePause = async () => {
    setEmail((prevState: any) => ({ ...prevState, status: STATUS.PAUSING }));
    hasToPause.current = true;
  };

  if (!analysis && !email && !sender) return <Loading />;

  return (
    <>
      <Header
        title={t("sendEmailTitle")}
        subtitle={email.title}
        breadCrumbItems={[
          { title: t("breadcrumb.dashboard"), href: "/" },
          { title: t("breadcrumb.emails"), href: "/emails" },
          { title: t("breadcrumb.sendEmail") },
        ]}
      />
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">{t("progress")}</h3>
        </div>

        <div className="card-body">
          <div className="row">
            <div className="col-sm-6 col-lg">
              <div className="media align-items-center">
                <span className="mr-3">
                  <Envelope size={20} />
                </span>
                <div className="media-body">
                  <span className="d-block font-size-sm">{t("totalEmails")}</span>
                  <div className="d-flex align-items-center">
                    <h3 className="mb-0">{analysis?.emailsFoundCount}</h3>
                  </div>
                </div>
              </div>
              <div className="d-lg-none">
                <hr />
              </div>
            </div>
            <div className="col-sm-6 col-lg column-divider-lg">
              <div className="media align-items-center">
                <span className="mr-3">
                  <Envelope size={20} />
                </span>
                <div className="media-body">
                  <span className="d-block font-size-sm">{t("emailsPending")}</span>
                  <div className="d-flex align-items-center">
                    <h3 className="mb-0">
                      {analysis?.emailsFoundCount - email?.sent > 0 ? analysis?.emailsFoundCount - email?.sent || 0 : 0}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="d-lg-none">
                <hr />
              </div>
            </div>
            <div className="col-sm-6 col-lg column-divider-lg">
              <div className="media align-items-center">
                <span className="mr-3">
                  <Envelope size={20} />
                </span>

                <div className="media-body">
                  <span className="d-block font-size-sm">{t("emailsSent")}</span>
                  <div className="d-flex align-items-center">
                    <h3 className="mb-0">{email.sent}</h3>
                  </div>
                </div>
              </div>
              <div className="d-sm-none">
                <hr />
              </div>
            </div>
          </div>
        </div>
        <hr className="my-0"></hr>
        <div className="card-body pt-0">
          <Notification message={errorMessage} type="danger" />
          <ProgressBar progress={(email?.sent / analysis?.emailsFoundCount) * 100} />
          <div className="mt-3 text-right">
            {email.status === STATUS.NOT_STARTED && (
              <button className="btn btn-primary mr-2" onClick={handleStart} disabled={false}>
                <span className="mr-2">
                  <PlayFill size={22} />
                </span>
                <span>{t("send")}</span>
              </button>
            )}

            {console.log(email.status)}

            {(email.status === STATUS.PAUSED || email.status === STATUS.ERROR) && (
              <button className="btn btn-primary mr-2" onClick={handleStart} disabled={false}>
                <span className="mr-2">
                  <PlayFill size={22} />
                </span>
                <span>{t("resume")}</span>
              </button>
            )}

            {email.status === STATUS.STARTING && (
              <button className="btn btn-primary mr-2" onClick={handleStart} disabled={true}>
                <span className="mr-2">
                  <div className="spinner-border spinner-border-sm align-middle" role="status">
                    <span className="sr-only">{t("starting")}...</span>
                  </div>
                </span>
                <span>{t("starting")}</span>
              </button>
            )}

            {email.status === STATUS.PAUSING && (
              <button className="btn btn-primary mr-2" onClick={handleStart} disabled={true}>
                <span className="mr-2">
                  <div className="spinner-border spinner-border-sm align-middle" role="status">
                    <span className="sr-only">{t("pausing")}...</span>
                  </div>
                </span>
                <span className="m-0 p-0 align-middle">{t("pausing")}</span>
              </button>
            )}

            {email.status === STATUS.RUNNING && (
              <button className="btn btn-primary mr-2" onClick={handlePause} disabled={false}>
                <span className="mr-2">
                  <PauseFill size={22} />
                </span>
                <span>{t("pause")}</span>
              </button>
            )}

            {email.status === STATUS.COMPLETED && (
              <button className="btn btn-primary mr-2" disabled={true}>
                <span className="mr-2">
                  <PlayFill size={22} />
                </span>
                <span>{t("start")}</span>
              </button>
            )}
          </div>
        </div>
      </div>
      <PremiumModal show={show} setShow={setShow} />
    </>
  );
}

export default SendEmail;
