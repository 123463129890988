import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "./firebase";
import { Sender } from "../../types/SenderTypes";

export const getSender = async (userId: string, senderId: string): Promise<any> => {
  const userRef = doc(db, "users", userId);
  const senderRef = doc(userRef, "senders", senderId);
  const docSnap = await getDoc(senderRef);
  if (!docSnap.exists()) return null;
  const sender = docSnap.data();
  sender.id = docSnap.id;
  return sender;
};

export const updateSender = async (userId: string, senderId: string, sender: Sender) => {
  const userRef = doc(db, "users", userId);
  const emailAddressRef = doc(userRef, "senders", senderId);
  await updateDoc(emailAddressRef, sender as { [x: string]: any });
};

export const createSender = async (userId: string, sender: Sender) => {
  const userRef = doc(db, "users", userId);
  const newSender = (await addDoc(collection(userRef, "senders"), sender)) as any;
  return newSender;
};

/**
 * Obtiene los emisores (senders) con la posibilidad de paginar, filtrar y ordenar.
 *
 * @param {string} userId - El ID del usuario.
 * @param {string} provider - El tipo de proveedor por el cual filtrar.
 * @param {string} orderByField - El campo por el cual ordenar.
 * @param {number} pageSize - El tamaño de la página para la paginación.
 * @param {any} lastVisible - El último documento visible de la página anterior para la paginación.
 * @returns {Promise<any[]>} - Una promesa que resuelve en una lista de emisores.
 */
export const getSenders = async (
  userId: string,
  filters: Object | null = null,
  orderByField = "email",
  pageSize = 100,
  lastVisible = null
) => {
  let q;
  const userRef = doc(db, "users", userId);
  const sendersRef = collection(userRef, "senders");

  if (filters) {
    const whereClauses = [];
    for (const [field, value] of Object.entries(filters) as any) {
      value !== undefined && whereClauses.push(where(field, "==", value));
    }
    q = query(sendersRef, ...whereClauses);
  } else {
    q = query(sendersRef);
  }

  q = query(q, orderBy(orderByField));

  if (lastVisible) {
    q = query(q, startAfter(lastVisible));
  }

  q = query(q, limit(pageSize));

  const querySnapshot = await getDocs(q);
  const senders = [] as any;
  querySnapshot.forEach((doc) => {
    let sender = doc.data();
    sender.id = doc.id;
    senders.push(sender);
  });

  return senders;
};

export const deleteSender = async (userId: string, senderId: string) => {
  const userRef = doc(db, "users", userId);
  const emailAddressRef = doc(userRef, "senders", senderId);
  await deleteDoc(emailAddressRef);
};
