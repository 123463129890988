import React from "react";
import { Modal } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";

function ConfirmPurchase({ show, setShow, handlePurchase, plan, previewPrice, loading, paymentIsLoading }: any) {
  const { t } = useTranslation("Product.ConfirmPurchase");

  return (
    <Modal show={show} onHide={() => setShow(false)} size="sm" backdrop={paymentIsLoading ? "static" : true} keyboard={!paymentIsLoading}>
      <Modal.Header>
        <Modal.Title>{t("title")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-body">
            <h6>{t("monthlySubscription")}</h6>
            <h4>
              {new Intl.NumberFormat("es-ES", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 3,
              }).format(plan?.price)}{" "}
              / {t("month")}
            </h4>
          </div>
        </div>
        <div className="d-flex justify-content-between my-4 px-2">
          <p className="m-0">
            {t("amountDueToday")}
            <small className="d-block m-0">
              <Moment format="D [de] MMMM [de] YYYY">{Date.now()}</Moment>
            </small>
          </p>
          <div className="text-success my-auto">
            {loading ? (
              <Skeleton width={80} />
            ) : (
              new Intl.NumberFormat("es-ES", {
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 3,
              }).format(previewPrice)
            )}
          </div>
        </div>

        <button className="btn btn-primary w-100" onClick={handlePurchase} disabled={loading || paymentIsLoading}>
          {paymentIsLoading ? (
            <div className="spinner-border spinner-border-sm m-0" role="status"></div>
          ) : (
            <>
              {previewPrice === 0 ? (
                t("confirm")
              ) : (
                <>
                  {t("pay")}{" "}
                  {new Intl.NumberFormat("es-ES", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 3,
                  }).format(previewPrice)}
                </>
              )}
            </>
          )}
        </button>
        <p className="small text-center mb-0 mt-3">{t("noCommitment")}</p>
        <div className="text-center">
          <img
            src="/assets/img/store/powered-by-stripe.png"
            alt="Secure payment badge"
            className="img-fluid mt-3 mx-auto text-center"
            style={{ maxWidth: 100 }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ConfirmPurchase;
